import React from 'react';
import { Grid, Paper, Typography, Box } from '@mui/material';

const CourseDetails = () => {
  const courseModules = ['HTML & CSS', 'JavaScript', 'React', 'Node.js', 'Full-Stack Project'];

  return (
    <Grid 
      container 
      spacing={3} 
      style={{ 
        minHeight: '50vh', 
        padding: '20px', 
        background: 'linear-gradient(45deg, rgba(255, 0, 0, 0.6), rgba(0, 0, 255, 0.6)), url(https://cdn.gamma.app/theme_images/standard/2cefabf5a81346e9941815c7af8e899a/original/Gamma-Dark---28.jpg)', // Gradient and background image combined
        backgroundSize: 'cover', 
        backgroundPosition: 'center', 
        overflow: 'hidden',
      }}
    >
      
      {/* Left Section - Course Details */}
      <Grid item xs={12} md={3}>
        <Paper elevation={3} style={{ marginTop: '40px', padding: '20px', backgroundColor: 'rgba(39, 19, 102, 0.86)' }}>
          <Typography variant="h5" style={{ marginBottom: '20px', color: '#008080', textAlign:'center' }}>
            Course Highlights
          </Typography>
          <Box component="ul" style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
            {courseModules.map((module, index) => (
              <Paper 
                key={index} 
                elevation={3} 
                style={{ 
                  padding: '15px', 
                  marginBottom: '10px', 
                  textAlign: 'center', 
                  backgroundColor: '#fff', 
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  cursor: 'pointer',
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = 'scale(1.05)';
                  e.currentTarget.style.boxShadow = '0px 15px 30px rgba(56, 228, 201, 0.86)';
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = 'scale(1)';
                  e.currentTarget.style.boxShadow = 'none';
                }}
              >
                <Typography variant="h6">
                  {module}
                </Typography>
              </Paper>
            ))}
          </Box>
        </Paper>
      </Grid>
      
      {/* Middle Section */}
      <Grid item xs={12} md={6}>
        <Paper 
          elevation={3} 
          style={{
            marginTop: '40px', 
            padding: '20px', 
            backgroundImage: `url('https://cdn.gamma.app/hwqiyqnz3pr3zh3/generated-images/8gUimym450nDQAfk455Yd.jpg')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            minHeight: '400px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            textAlign: 'center',
            borderRadius: '8px', 
          }}
        >
          <Typography variant="h4" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', padding: '10px', color: 'white' }}>
            Become a Full-Stack Developer
          </Typography>
        </Paper>
      </Grid>
      
      {/* Right Section - More Course Info */}
      <Grid item xs={12} md={3}>
        <Paper elevation={3} style={{ marginTop: '40px',  padding: '20px', backgroundColor: 'rgba(39, 19, 102, 0.86)' }}>
          <Typography variant="h5" style={{ marginBottom: '20px', color: '#008080' }}>
            Why Choose This Course?
          </Typography>
          <Typography variant="body1" style={{ marginBottom: '15px' ,color : '#f9f7fa' }}>
            - Expert instructor with real-world experience.
          </Typography>
          <Typography variant="body1" style={{ marginBottom: '15px', color : '#f9f7fa' }}>
            - Flexible Learning at Your Own Pace
          </Typography>
          <Typography variant="body1" style={{ marginBottom: '15px', color : '#f9f7fa' }}>
          - Ongoing Support and Networking
          </Typography>
          <Typography variant="body1" style={{ marginBottom: '15px', color : '#f9f7fa' }}>
            - Hands-on projects to build a professional portfolio.
          </Typography>
        </Paper>
      </Grid>
      
    </Grid>
  );
};

export default CourseDetails;
