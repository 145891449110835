
import React, { useState } from "react";
import {getAuth, createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBRadio,
  MDBCardImage
} from 'mdb-react-ui-kit';

const baseURL = process.env.REACT_APP_BASE_URL;

const Register = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    birthday: "",
    gender: "",
    course: "",
    email: "",
    password: ""
  });

  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };


  const handleRegister = async (e) => {
    e.preventDefault();
    setError("");
  
    if (!formData.firstName || !formData.lastName || !formData.email || !formData.password) {
      setError("All fields are required.");
      return;
    }
  
    if (formData.password.length < 8) {
      setError("Password must be at least 8 characters.");
      return;
    }
  
    try {
      // Step 1: Create user in Firebase
      const auth = getAuth();
      const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
      const user = userCredential.user;
  
      // Step 2: Update Firebase `displayName`
      await updateProfile(user, { displayName: formData.firstName });
  
      // Step 3: Get Firebase ID Token
      const idToken = await user.getIdToken();
      console.log("Firebase Token:", idToken);
  
      // Step 4: Send user details + Firebase token to backend
      await axios.post(`${baseURL}/api/auth/register`, {
        firstName: formData.firstName,
        lastName: formData.lastName,
        birthday: formData.birthday,
        gender: formData.gender,
        course: formData.course,
        email: formData.email,
        firebaseToken: idToken, // ✅ Pass the token
      }, {
        headers: {
          Authorization: `Bearer ${idToken}` // Firebase ID Token should be included here
        }
      });
      // Step 5: Redirect to dashboard
      navigate("/dashboard");
  
    } catch (err) {
      setError("Error creating account: " + err.message);
    }
  };
  
  return (
    <MDBContainer fluid className='bg-dark' style={{ minHeight: '80vh' }}>
      <MDBRow className='d-flex justify-content-center align-items-center h-100'>
        <MDBCol>
          <MDBCard className='my-4'>
            <MDBRow className='g-0' style={{ height: '800px' }}>
              <MDBCol md='6' className="d-none d-md-block" style={{ height: '800px' }}>
                <MDBCardImage 
                  src='https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-registration/img4.webp' 
                  alt="Sample photo" 
                  className="rounded-start" 
                  fluid
                  style={{  width: '100%',height: '100%', objectFit: 'cover' }}
                />
              </MDBCol>

              <MDBCol md='6'>
                <MDBCardBody className='text-black d-flex flex-column justify-content-center' style={{ height: '80vh' }}>
                  <h3 className="mb-5 text-uppercase fw-bold">Student Registration Form</h3>

                  {error && <p className="text-danger">{error}</p>}

                  <MDBRow>
                    <MDBCol md='6'>
                      <MDBInput 
                        wrapperClass='mb-4' 
                        label='First Name' 
                        size='lg' 
                        id='form1' 
                        type='text' 
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                      />
                    </MDBCol>

                    <MDBCol md='6'>
                      <MDBInput 
                        wrapperClass='mb-4' 
                        label='Last Name' 
                        size='lg' 
                        id='form2' 
                        type='text'
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                      />
                    </MDBCol>
                  </MDBRow>

                  <MDBInput 
                    wrapperClass='mb-4' 
                    label='Birthday' 
                    size='lg' 
                    id='form3' 
                    type='date'
                    name="birthday"
                    value={formData.birthday}
                    onChange={handleChange}
                  />

                  <div className='d-md-flex justify-content-start align-items-center mb-4'>
                    <h6 className="fw-bold mb-0 me-4">Gender: </h6>
                    <MDBRadio name='gender' id='female' value='Female' label='Female' inline onChange={handleChange} />
                    <MDBRadio name='gender' id='male' value='Male' label='Male' inline onChange={handleChange} />
                    <MDBRadio name='gender' id='other' value='Other' label='Other' inline onChange={handleChange} />
                  </div>

                  <MDBInput 
                    wrapperClass='mb-4' 
                    label='Course' 
                    size='lg' 
                    id='form5' 
                    type='text'
                    name="course"
                    value={formData.course}
                    onChange={handleChange}
                  />

                  <MDBInput 
                    wrapperClass='mb-4' 
                    label='Email Address' 
                    size='lg' 
                    id='form6' 
                    type='email'
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />

                  <MDBInput 
                    wrapperClass='mb-4' 
                    label='Password' 
                    size='lg' 
                    id='form7' 
                    type='password'
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                  />

                  <div className="d-flex justify-content-end pt-3">
                    <MDBBtn color='light' size='lg'>Reset</MDBBtn>
                    <MDBBtn className='ms-2' color='warning' size='lg' onClick={handleRegister}>Submit</MDBBtn>
                  </div>
                </MDBCardBody>
              </MDBCol>
            </MDBRow>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default Register;
