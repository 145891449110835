// import React, { useState, useEffect } from "react";
// import {
//   MDBContainer,
//   MDBRow,
//   MDBCol,
//   MDBCard,
//   MDBCardBody,
//   MDBProgress,
//   MDBProgressBar,
// } from "mdb-react-ui-kit";
// import Sidebar from "./SideBar";
// import { auth } from "../firebaseConfig";
// import { useNavigate } from "react-router-dom";
// import { onAuthStateChanged } from "firebase/auth";
// import axios from "axios";

// const StudentDashboard = () => {
//   const [user, setUser] = useState(null);
//   const [gpa, setGpa] = useState(0);
//   const [progress, setProgress] = useState(0);
//   const [name, setName] = useState(""); // State for user's name
//   const [avatar, setAvatar] = useState(""); // Avatar state
//   const navigate = useNavigate();

//   useEffect(() => {
//     const unsubscribe = onAuthStateChanged(auth, async (user) => {
//       if (user) {
//         console.log("User is logged in:", user);
//         setUser(user);
//         try {
//           const response = await axios.get(
//             `http://localhost:5000/api/users/${user.email}`
//           );
//           setGpa(response.data.gpa || 3.8);
//           setProgress(response.data.progress.completed || 70);
//           setName(response.data.name || "Student"); // Fetch the user's name
//           setAvatar(response.data.avatar || ""); // 
//         } catch (error) {
//           console.error("Error fetching user data:", error);
//         }
//       } else {
//         navigate("/login");
//       }
//     });

//     return () => unsubscribe();
//   }, [navigate]);

//   const handleAvatarUpload = async (event) => {
//     const file = event.target.files[0];
//     if (!file) return;

//     const formData = new FormData();
//     formData.append("avatar", file);

//     try {
//       const response = await axios.post(
//         `http://localhost:5000/api/users/${user.email}/upload-avatar`,
//         formData,
//         { headers: { "Content-Type": "multipart/form-data" } }
//       );
//       setAvatar(response.data.avatar);
//     } catch (error) {
//       console.error("Error uploading avatar:", error);
//     }
//   };

//   return (
//     <MDBContainer fluid className="p-0">
//       <MDBRow className="g-0">
//         {/* Sidebar (Navigation) */}
//         <MDBCol md="2" className="bg-dark text-white p-3">
//           <Sidebar user={user} avatar={avatar} onAvatarUpload={handleAvatarUpload} />
//         </MDBCol>

//         {/* Main Content Area */}
//         <MDBCol md="7" className="bg-light p-4">
//           <h3 className="mb-4">Welcome, {name || "Student"}!</h3>

//           {/* GPA & Progress Section */}
//           <MDBCard className="mb-4">
//             <MDBCardBody>
//               <h5>Current GPA: <strong>{gpa.toFixed(2)}</strong></h5>
//               <h5>Course Completion Progress:</h5>
//               <MDBProgress height="20">
//                 <MDBProgressBar width={progress} valuemin={0} valuemax={100}>
//                   {progress}%
//                 </MDBProgressBar>
//               </MDBProgress>
//             </MDBCardBody>
//           </MDBCard>

//           {/* Upcoming Assignments */}
//           <MDBCard className="mb-4">
//             <MDBCardBody>
//               <h5>Upcoming Assignments</h5>
//               <ul>
//                 <li>Project Submission - March 10</li>
//                 <li>JavaScript Quiz - March 12</li>
//                 <li>React Workshop - March 15</li>
//               </ul>
//             </MDBCardBody>
//           </MDBCard>

//           {/* Announcements */}
//           <MDBCard className="mb-4">
//             <MDBCardBody>
//               <h5>Announcements</h5>
//               <p>New resources added for React fundamentals!</p>
//             </MDBCardBody>
//           </MDBCard>
//         </MDBCol>

//         {/* Right Panel (Resources & Community) */}
//         <MDBCol md="3" className="bg-light p-3">
//           {/* Quick Access to Materials */}
//           <MDBCard className="mb-4">
//             <MDBCardBody>
//               <h5>Quick Access</h5>
//               <ul>
//                 <li>Downloadable Materials</li>
//                 <li>Video Lectures</li>
//                 <li>Discussion Forums</li>
//               </ul>
//             </MDBCardBody>
//           </MDBCard>

//           {/* Community & Networking */}
//           <MDBCard>
//             <MDBCardBody>
//               <h5>Community & Networking</h5>
//               <p>Connect with peers and join student groups!</p>
//             </MDBCardBody>
//           </MDBCard>
//         </MDBCol>
//       </MDBRow>
//     </MDBContainer>
//   );
// };

// export default StudentDashboard;

import React, { useState, useEffect } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBProgress,
  MDBProgressBar,
} from "mdb-react-ui-kit";
import Sidebar from "./SideBar";
import { auth } from "../firebaseConfig";
import { useNavigate } from "react-router-dom";
import {signOut, onAuthStateChanged } from "firebase/auth";
import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL;

const StudentDashboard = () => {
  const [user, setUser] = useState(null);
  const [gpa, setGpa] = useState(0);
  const [progress, setProgress] = useState(0);
  const [firstName, setFirstName] = useState(""); // Updated to firstName
  const [avatar, setAvatar] = useState(""); 
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        console.log("✅ User logged in:", user);
        setUser(user);
        try {
          const idToken = await user.getIdToken();

          const response = await axios.get(
            `${baseURL}/api/auth/user`,
            {
              headers: {
                Authorization: `Bearer ${idToken}`,
              },
            }
          );
          setGpa(response.data.gpa || 3.8);
          setProgress(response.data.progress.completed || 70);
          setFirstName(response.data.firstName || "Student"); // Fetch first name
          setAvatar(`${baseURL}${response.data.avatar}` || ""); // Fetch avatar

        } catch (error) {
          console.error("❌ Error fetching user data:", error);
        }
      } else {
        navigate("/login");
      }
    });

    return () => unsubscribe();
  }, [navigate]);


  const handleAvatarUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
  
    const formData = new FormData();
    formData.append("avatar", file);
  
    const user = auth.currentUser;
    if (!user) {
      console.error("No user authenticated");
      return;
    }
  
    try {
      const token = await user.getIdToken(); // Get Firebase auth token
  
      const response = await axios.post(`${baseURL}/api/avatar/upload-avatar`, formData, {
        headers: {
          Authorization: `Bearer ${token}`, // Send token in request
          "Content-Type": "multipart/form-data",
        },
      });
  
      console.log("Avatar uploaded successfully:", response.data.avatarUrl);
    } catch (error) {
      console.error("Error uploading avatar:", error);
    }
  };



   // Logout function
   const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login'); // Redirect to login page after logout
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  return (
    <MDBContainer fluid className="p-0">
      <MDBRow className="g-0">
        {/* Sidebar (Navigation) */}
        <MDBCol md="2" className="bg-dark text-white p-3">
          <Sidebar user={user} avatar={avatar} onLogout={handleLogout} onAvatarUpload={handleAvatarUpload} />
        </MDBCol>

        {/* Main Content Area */}
        <MDBCol md="7" className="bg-light p-4">
          <h3 className="mb-4">Welcome, {firstName || "Student"}!</h3>

          {/* GPA & Progress Section */}
          <MDBCard className="mb-4">
            <MDBCardBody>
              <h5>Current GPA: <strong>{gpa.toFixed(2)}</strong></h5>
              <h5>Course Completion Progress:</h5>
              <MDBProgress height="20">
                <MDBProgressBar width={progress} valuemin={0} valuemax={100}>
                  {progress}%
                </MDBProgressBar>
              </MDBProgress>
            </MDBCardBody>
          </MDBCard>

          {/* Upcoming Assignments */}
          <MDBCard className="mb-4">
            <MDBCardBody>
              <h5>Upcoming Assignments</h5>
              <ul>
                <li>Project Submission - March 10</li>
                <li>JavaScript Quiz - March 12</li>
                <li>React Workshop - March 15</li>
              </ul>
            </MDBCardBody>
          </MDBCard>

          {/* Announcements */}
          <MDBCard className="mb-4">
            <MDBCardBody>
              <h5>Announcements</h5>
              <p>New resources added for React fundamentals!</p>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>

        {/* Right Panel (Resources & Community) */}
        <MDBCol md="3" className="bg-light p-3">
          {/* Quick Access to Materials */}
          <MDBCard className="mb-4">
            <MDBCardBody>
              <h5>Quick Access</h5>
              <ul>
                <li>Downloadable Materials</li>
                <li>Video Lectures</li>
                <li>Discussion Forums</li>
              </ul>
            </MDBCardBody>
          </MDBCard>

          {/* Community & Networking */}
          <MDBCard>
            <MDBCardBody>
              <h5>Community & Networking</h5>
              <p>Connect with peers and join student groups!</p>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default StudentDashboard;
