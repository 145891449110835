import 'bootstrap/dist/css/bootstrap.min.css';  // Add this line at the top
import { Helmet } from 'react-helmet';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/Header';
import CourseDetails from './components/CourseDetails';
// import SignUpForm from './components/SignUpForm';
import Testimonials from './components/Testimonials';
import Footer from './components/Footer';
import JavaScriptPractice from './components/JavaScriptPractice';
import Modal from './components/Modal';
import CongratsPage from './components/CongratsPage';
import CourseSyllabus from './components/CourseSyllabus';
import PrivacyPolicy from './components/PrivacyPolicy';
import AboutDevPursuit from './components/AboutDevPursuit'; 
import EbookDownloadPage from './components/EBookDownloadPage';
import MentorshipPitch from './components/MentorshipPitch';
import Login from './components/Login';
import Register from './components/Register';
import PrivateRoute from './components/PrivateRoute';
import './App.css';
import StudentDashboard from './components/StudentDashboard';
import MyNavbar from './components/MyNavBar';

function App() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [hasScrolledToSection, setHasScrolledToSection] = useState(false);



  const closeModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const courseSection = document.getElementById('course-syllabus-section');
      if (courseSection && !hasScrolledToSection) {
        const rect = courseSection.getBoundingClientRect();

        // Check if section is in view
        if (rect.top <= window.innerHeight) {
          setHasScrolledToSection(true); // Avoid re-triggering

          // Delay modal open with smooth transition
          setTimeout(() => {
            setModalOpen(true);
          }, 2000); // 2-second delay for smoother experience
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [hasScrolledToSection]);

  return (
    <Router>
      <div className="App">
        <Helmet>
          <link rel="canonical" href="https://www.devpursuit.com" />
        </Helmet>

        {/* Header is always displayed */}
        <Header />

        <MyNavbar />

        {/* Modal that triggers based on scroll */}
        {isModalOpen && <Modal isOpen={isModalOpen} onClose={closeModal} />}

          <div className="content">
            <Routes>
              {/* Public Routes */}
              <Route
                path="/"
                element={
                  <>
                    <Helmet>
                      <title>DevPursuit | Home</title>
                    </Helmet>
                    <CourseDetails />
                    
                    <section id="course-syllabus-section">
                      <CourseSyllabus />
                    </section>
                    <Testimonials />
                  </>
                }
              />
              <Route
                path="/login"
                element={<Login />}
              />
              <Route
                path="/register"
                element={<Register />}
              />

              {/* Private Route for Dashboard */}
              <Route
                path="/dashboard"
                element={
                  <PrivateRoute element={<StudentDashboard />} />
                }
              />

              {/* Other Routes */}
              <Route
                path="/about"
                element={<AboutDevPursuit />}
              />
              <Route
                path="/practice"
                element={<JavaScriptPractice />}
              />
              <Route
                path="/congratulations"
                element={<CongratsPage />}
              />
              <Route
                path="/privacy-policy"
                element={<PrivacyPolicy />}
              />
              <Route
                path="/ebooks"
                element={<EbookDownloadPage />}
              />
              <Route
                path="/mentorship"
                element={<MentorshipPitch />}
              />

              {/* Catch-all route */}
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </div>
        </div>

        {/* Footer is always displayed */}
        <Footer />
      {/* </div> */}
    </Router>
  );
}

export default App;
