import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { auth } from '../firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';

const PrivateRoute = ({ element, ...rest }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);  // Update user state when authentication state changes
    });

    // Clean up the subscription when component unmounts
    return () => unsubscribe();
  }, []);

  if (user === null) {
    return <div>Loading...</div>;  // Show loading state while checking user authentication
  }

  return user ? element : <Navigate to="/login" />;  // Redirect to login if not authenticated
};

export default PrivateRoute;
