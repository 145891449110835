// Import Firebase SDK
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// Your Firebase Config (Replace with your own keys)
const firebaseConfig = {
  apiKey: "AIzaSyAwQYdgMNRwk90WI6Sv6BKSllK4z4Eeqpg",
  authDomain: "devpursuit-15d90.firebaseapp.com",
  projectId: "devpursuit-15d90",
  storageBucket: "devpursuit-15d90.firebasestorage.app",
  messagingSenderId: "377364923880",
  appId: "1:377364923880:web:843c73854993304293905d",
  measurementId: "G-XB6B6DLZQQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
