import React from 'react';
import '../Testimonials.css'; 


const testimonialsData = [
  {
    quote: "This course offers a unique perspective that every aspiring professional needs.",
    author: "Dr. Emily Carter, Industry Expert",
  },
  {
    quote: "An invaluable resource for anyone looking to excel in their career.",
    author: "Mark Smith, Senior Developer at Tech Innovations",
  },
  {
    quote: "I highly recommend this course. Shaniece's insights are exceptional!",
    author: "Lisa Johnson, Project Manager at Future Tech",
  },
  {
    quote: "The curriculum is well-structured and aligned with industry needs.",
    author: "James Brown, CTO of Digital Solutions",
  },
  {
    quote: "Prepare for a transformative learning experience with this course!",
    author: "Sarah Davis, Career Coach",
  },
];

const Testimonials = () => {
  return (
    <section className="testimonials">
     
         <h2>What Industry Leaders Are Saying</h2>
      <div className="testimonial-container">
   
        {testimonialsData.map((testimonial, index) => (
          <blockquote key={index} className="testimonial">
            <p className="quote">"{testimonial.quote}"</p>
            <footer className="author">- {testimonial.author}</footer>
          </blockquote>
        ))}
      </div>
    </section>
  );
};

export default Testimonials;
