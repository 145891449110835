import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Modal.css';

const Modal = ({ isOpen, onClose }) => {
    const navigate = useNavigate();

    const handleNavigate = () => {
        onClose(); // Close the modal
        navigate('/practice'); // Redirect to the practice page
    };

    if (!isOpen) return null;

    return (
        <div className={`modal ${isOpen ? 'show' : ''}`} style={modalStyles.overlay}>
            <div style={modalStyles.modal}>
                <h2>Want To Test Your Coding Skills?</h2>
                <p>Click the button below to start practicing JavaScript.</p>
                
                {/* Button to navigate */}
                <button onClick={handleNavigate} style={modalStyles.buttonNavigate}>
                    Go to Editor
                </button>

                {/* Button to close modal */}
                <button onClick={onClose} style={modalStyles.buttonClose}>
                    Close
                </button>
            </div>
        </div>
    );
};

const modalStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    },
    modal: {
        background: '#fff',
        padding: '20px',
        borderRadius: '5px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        width: '600px', // Optional: Control the width of the modal
        textAlign: 'center', // Optional: Ensure text is centered
        color: '#pink', // Optional: Change the text color
    },
    buttonNavigate: {
        backgroundColor: '9881d7',
        color: 'white',
        padding: '10px 20px',
        margin: '10px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
        transition: 'background-color 0.3s ease',
        marginLeft: '40%',
    },
    buttonClose: {
        backgroundColor: '9881d7',
        color: 'white',
        padding: '10px 20px',
        margin: '10px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
        transition: 'background-color 0.3s ease',
        marginLeft: '43%',

    },
};

// Adding hover effects for the buttons
modalStyles.buttonNavigate[':hover'] = {
    backgroundColor: '#45a049',
};

modalStyles.buttonClose[':hover'] = {
    backgroundColor: '#e53935',
};

export default Modal;
