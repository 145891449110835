import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  MDBNavbar,
  MDBContainer,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavbarNav,
  MDBNavbarItem,
} from "mdb-react-ui-kit";

const MyNavbar = () => {
  const [openNav, setOpenNav] = useState(false);

  return (
    <MDBNavbar expand="lg" dark bgColor="dark">
      <MDBContainer fluid>
        {/* Navbar Toggler for Mobile */}
        <MDBNavbarToggler onClick={() => setOpenNav(!openNav)} />

        {/* Navbar Collapse */}
        <MDBCollapse navbar show={openNav}>
          <MDBNavbarNav className="d-flex justify-content-center w-100">
            <MDBNavbarItem>
              <Link to="/" className="nav-link">Home</Link>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <Link to="/about" className="nav-link">About</Link>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <Link to="/ebooks" className="nav-link">Ebooks</Link>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <Link to="/mentorship" className="nav-link">Mentorship</Link>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <Link to="/login" className="nav-link">Login/Register</Link>
            </MDBNavbarItem>
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBContainer>
    </MDBNavbar>
  );
};

export default MyNavbar;
