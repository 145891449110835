import React from 'react';
import "../App.css"


const Header = () => {
  return (
    <header className="header">
      <h1>Foundations in Software Engineering: From Zero to Full-Stack Developer</h1>
      

   
    </header>
  );
};

export default Header;
