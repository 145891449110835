import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; 
import { signInWithEmailAndPassword } from "firebase/auth"; 
import { auth } from "../firebaseConfig"; 

import {
  MDBContainer,
  MDBInput,
  MDBCheckbox,
  MDBBtn,
  MDBIcon
} from 'mdb-react-ui-kit';

const Login = () => {
  const [email, setEmail] = useState(""); 
  const [password, setPassword] = useState(""); 
  const [error, setError] = useState(""); 
  const navigate = useNavigate(); 

  // ✅ Handle form submission
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/dashboard"); 
    } catch (err) {
      setError("Invalid email or password");
    }
  };

  return (
    <MDBContainer className="p-3 my-5 d-flex flex-column w-50">
      <h2 className="text-center mb-4" >Sign In</h2>

      {/* ✅ Show error messages */}
      {error && <p className="text-danger text-center">{error}</p>}

      {/* ✅ Add onChange to update state */}
      <MDBInput 
        wrapperClass='mb-4' 
        label='Email address' 
        id='form1' 
        type='email' 
        value={email}
        onChange={(e) => setEmail(e.target.value)} 
      />

      <MDBInput 
        wrapperClass='mb-4' 
        label='Password' 
        id='form2' 
        type='password' 
        value={password}
        onChange={(e) => setPassword(e.target.value)} 
      />

    <div className="text-center w-100">
      <div className="d-flex justify-content-between mx-3 mb-4">
        <MDBCheckbox name='flexCheck' value='' id='flexCheckDefault' label='Remember me' />
        <a href="#">Forgot password?</a>
      </div>

      {/* ✅ Add onClick to trigger login */}
      <MDBBtn className="mb-4" onClick={handleLogin}>Sign in</MDBBtn>

      <div className="text-center">
        <p>Not a member? <a href="/register">Register</a></p>
        <p>or sign up with:</p>

        <div className='d-flex justify-content-between mx-auto' style={{width: '40%'}}>
          <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#3b5998' }}>
            <MDBIcon fab icon='facebook-f' size="sm"/>
          </MDBBtn>

          <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#55acee' }}>
            <MDBIcon fab icon='twitter' size="sm"/>
          </MDBBtn>

          <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#dd4b39' }}>
            <MDBIcon fab icon='google' size="sm"/>
          </MDBBtn>

          <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#333' }}>
            <MDBIcon fab icon='github' size="sm"/>
          </MDBBtn>
        </div>
      </div>
    </div>
    </MDBContainer>
  );
};

export default Login;
