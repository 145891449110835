import React from 'react';
import { MDBNavbar, MDBNavbarNav, MDBNavbarItem, MDBNavbarLink, MDBIcon, MDBContainer } from 'mdb-react-ui-kit';
import { useLocation } from 'react-router-dom';

const placeholderAvatar = '/Images/default-avatar.png'; // React will serve this from the public folder


const Sidebar = ({ user, avatar, onAvatarUpload, onLogout }) => {
  const location = useLocation();

  // Only render sidebar if on /dashboard route
  const isDashboard = location.pathname === '/dashboard';

  if (!isDashboard) return null;

  return (
    <MDBContainer fluid>
      <div className="d-flex">
        {/* Sidebar using MDBNavbar */}
        <MDBNavbar className="bg-dark" style={{ height: '100vh' }} dark expand="md" vertical>
          <MDBNavbarNav className="flex-column">
            <div className="text-white text-center py-4">
              {/* Clickable Avatar */}
              <div
                onClick={() => document.getElementById('avatarInput').click()}
                style={{
                  cursor: 'pointer',
                  width: '100px',
                  height: '100px',
                  borderRadius: '50%',
                  margin: 'auto',
                  // backgroundImage: `url(${avatar || '/Images/default-avatar.png'})`, // Display avatar
                  backgroundImage: `url(${avatar || placeholderAvatar})`, // Use avatar or placeholder

                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              />
              {/* User's Name */}
              <h5>{user ? user.displayName  : 'Loading...'}</h5> {/* Display name if user is available */}
              <p>Student</p>
              {/* Hidden Input for Avatar Upload */}
              <input
                id="avatarInput"
                type="file"
                accept="image/*"
                onChange={onAvatarUpload} // Handle avatar upload
                style={{ display: 'none' }}  // Hide the input field
              />
            </div>
            {/* Sidebar Links */}
            <MDBNavbarItem>
              <MDBNavbarLink to="/dashboard" className="text-white">
                <MDBIcon fas icon="home" /> Dashboard
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink to="/modules" className="text-white">
                <MDBIcon fas icon="clipboard-list" /> Modules 
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink to="/lessons" className="text-white">
                <MDBIcon fas icon="book" /> Lessons
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink to="/exercises" className="text-white">
                <MDBIcon fas icon="pen" /> Exercises
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink to="/quizzes" className="text-white">
                <MDBIcon fas icon="check-circle" /> Quizzes
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink to="/settings" className="text-white">
                <MDBIcon fas icon="cogs" /> Settings
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink to="/logout" className="text-white" onClick={onLogout}>
                <MDBIcon fas icon="sign-out-alt"  /> Logout
              </MDBNavbarLink>
            </MDBNavbarItem>
          </MDBNavbarNav>
        </MDBNavbar>
      </div>
    </MDBContainer>
  );
};

export default Sidebar;
